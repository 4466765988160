
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2V3H9V2C9 1.44772 8.55228 1 8 1C7.44772 1 7 1.44772 7 2V3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V10V20C2 20.7957 2.31607 21.5587 2.87868 22.1213C3.44129 22.6839 4.20435 23 5 23H8.5C9.05228 23 9.5 22.5523 9.5 22C9.5 21.4477 9.05228 21 8.5 21H5C4.73478 21 4.48043 20.8946 4.29289 20.7071C4.10536 20.5196 4 20.2652 4 20V11H8C8.55228 11 9 10.5523 9 10C9 9.44771 8.55228 9 8 9H4V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H7V6C7 6.55228 7.44772 7 8 7C8.55228 7 9 6.55228 9 6V5H15V6C15 6.55228 15.4477 7 16 7C16.5523 7 17 6.55228 17 6V5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V7.5C20 8.05228 20.4477 8.5 21 8.5C21.5523 8.5 22 8.05228 22 7.5V6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7957 3 19 3H17V2ZM16 13C16.5523 13 17 13.4477 17 14V15.7816L18.1402 16.7318C18.5645 17.0853 18.6218 17.7159 18.2682 18.1402C17.9147 18.5645 17.2841 18.6218 16.8598 18.2682L15.3598 17.0182C15.1318 16.8282 15 16.5468 15 16.25V14C15 13.4477 15.4477 13 16 13ZM12.4645 12.4645C13.4021 11.5268 14.6739 11 16 11C17.3261 11 18.5979 11.5268 19.5355 12.4645C20.4732 13.4021 21 14.6739 21 16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21C14.6739 21 13.4021 20.4732 12.4645 19.5355C11.5268 18.5979 11 17.3261 11 16C11 14.6739 11.5268 13.4021 12.4645 12.4645ZM16 9C14.1435 9 12.363 9.7375 11.0503 11.0503C9.7375 12.363 9 14.1435 9 16C9 17.8565 9.7375 19.637 11.0503 20.9497C12.363 22.2625 14.1435 23 16 23C17.8565 23 19.637 22.2625 20.9497 20.9497C22.2625 19.637 23 17.8565 23 16C23 14.1435 22.2625 12.363 20.9497 11.0503C19.637 9.7375 17.8565 9 16 9Z"/>
</svg>

  </template>

  <script>
  export default {
    name: 'FollowUp',
    inheritAttrs: true,
  }
  </script>
